.primary {
  color: $primary;
}

.violet-dark-9 {
  color: $violet-dark-9;
}

// .gold {
//   color: $gold-dark-5;
// }

.text-background-gold {
  @include text-background-gold;
}

.bold {
  font-weight: $font-bold;
}

.white {
  color: $white;
  opacity: 1;
}

.flex-container {
  display: flex;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-start {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-space {
  justify-content: space-around;
}

.wrap {
  flex-wrap: wrap;
}

.grow-1 {
  flex-grow: 1;
}

.ml-4 {
  margin-left: 4px;
}

.mr-4 {
  margin-right: 4px;
}

.mt-0 {
  margin-top: 0;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-32 {
  margin-top: 32px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-16 {
  margin-left: 16px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-16 {
  margin-right: 16px;
}

.inactive {
  opacity: 0.5 !important;
  pointer-events: none;
  user-select: none;
}

.pointer {
  cursor: pointer;
}

.font-small {
  font-size: $font-small;
}

.font-medium {
  font-size: $font-medium;
}

.font-large {
  font-size: $font-large;
}

.font-extra-large {
  font-size: $font-extra-large;
}

.font-extra-small {
  font-size: $font-extra-small;
}

.font-tiny {
  font-size: $font-tiny;
}

.font-bold {
  font-weight: $font-bold;
}

.nowrap {
  white-space: nowrap;
}
