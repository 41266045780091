// COLORS:
$background: #200d4b;

$violet-8: #291089;
$violet-dark-9: #1b0b5b;
$violet-dark-9-alpha: rgba(27, 11, 91, 0.5);
$violet-dark-9-alpha-2: rgba(27, 11, 91, 0.9);
$violet-light: #9a8bcd;

$blue-light: #c7f2ff;
$blue-light-alpha: rgba($blue-light, 0.2);

$gold-dark-9: #bf976e;
$gold-dark-5: #f7bd82;
$primary: $gold-dark-5;

$white: #f9f9fb;
$white-gray: #c0c6d8;

$card-background: rgba(236, 232, 252, 0.2);

// Gradients
$white-gray-gradient: linear-gradient(225deg, $white 0%, $white-gray 100%);
$gold-gradient: linear-gradient(90deg, $gold-dark-9 0%, $gold-dark-5 100%);
$gold-text-gradient: linear-gradient(
  40deg,
  #ffc182 0%,
  #bf9f7e 21%,
  #b98c5e 38.02%,
  #e5c9ad 55%,
  #f0b578 100%
);
$violet-gradient: linear-gradient(
  180deg,
  rgba(27, 11, 91, 0) 3.64%,
  rgba(27, 11, 91, 0.5) 49.93%,
  rgba(27, 11, 91, 0) 100%
);
// COLORS
$grey-hover: #a0a9c5;
$grey-light-1: #dfe2ec;

// MESSAGES
$message-error: #ff9b7a;
$message-error-border: #f15f24;
$message-error-bg: #553366;
$message-primary: #ccddff;
$message-primary-border: #3f80ff;
$message-primary-bg: #413685;

// BORDERS
$main-border-color: $blue-light-alpha;
$main-border: 1px solid $main-border-color;
// https://dev.to/afif/border-with-gradient-and-radius-387f
$main-border-radius-small: 8px;
$main-border-radius: 16px;
$main-border-radius-2: 32px;

// TYPOGRAPHY
// $font-extra-small: 0.64rem;
// $font-small: 0.8rem;
// $font-medium: 1rem;
// $font-large: 1.25rem;
// $font-extra-large: 1.563rem;
// $font-huge: 2.441rem;
$font-tiny: 10px;
$font-extra-small: 12px;
$font-small: 14px;
$font-medium: 16px;
$font-large: 18px;
$font-extra-large: 20px;

// TODO: Use bootstrap instead?
// FONT
$font-light: 300;
$font-regular: 500;
$font-bold: 700;

// TRANSITIONS
// $main-transition: all 0.3s ease 0s;

$footer-height: 392px;
$footer-height-mobile: 482px;

// Z-INDEX
$z-bedrock: -1;
$z-bottom: 0;
$z-base: 10;
$z-pop: 100;
$z-overlay: 200;
$z-modal: 200;
$z-topmost: 9999;

// BREAKPOINTS
$screen-desktop: 1200px;
$screen-tablet-big: 992px;
$screen-tablet: 768px;
$screen-mobile-big: 575px;
